/**
 * 商品页面专题相关API
 */

import request from '@/utils/request'

/**
 * 查询专题
 * @param params
 * @returns {Promise<any>}
 */
export function getSpecialList(data) {
  return request({
    url: 'seller/pages/siteGoodsNavigationShop/page',
    method: 'post',
    loading: true,
    data
  })
}

/**
 * 添加专题
 * @param params
 * @returns {Promise<any>}
 */
export function addSpecial(data) {
  return request({
    url: 'seller/pages/siteGoodsNavigationShop/add',
    method: 'post',
    loading: true,
    data
  })
}

/**
 * 编辑专题
 * @param params
 * @returns {Promise<any>}
 */
export function editSpecial(data) {
  return request({
    url: 'seller/pages/siteGoodsNavigationShop/edit',
    method: 'post',
    loading: true,
    data
  })
}

/**
 * 删除专题
 * @param params
 * @returns {Promise<any>}
 */
export function delSpecial(id) {
  return request({
    url: `seller/pages/siteGoodsNavigationShop/${id}`,
    method: 'delete',
    loading: true
  })
}

/**
 * 查询专题详情
 * @param params
 * @returns {Promise<any>}
 */
export function getSpecial(id) {
  return request({
    url: `seller/pages/siteGoodsNavigationShop/${id}`,
    method: 'get',
    loading: true
  })
}
