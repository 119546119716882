<template>
  <div class="wrap">
    <div class="container-preview">
      <div class="banner">
        <img :src="specialForm.image" v-if="specialForm.image"/>
      </div>
      <div class="goods-wrap-wrap">
        <div class="goods-wrap" @mouseenter="showOverlay=true">
          <div class="goods-item" v-for="(item,i) in goodsData" :key="i">
            <a :href="'/goods/' + item.goods_id">
              <div class="image-goods">
                <img :src="item.goods_image">
              </div>
              <div class="name-goods">{{ item.goods_name }}</div>
              <div>
                <div class="price-goods" v-if="item.revise_price">{{ item.revise_price | formatPrice }}</div>
                <div class="price-goods" v-else>{{ item.goods_price | formatPrice }}</div>
              </div>
            </a>
          </div>
          <div class="goods-wrap-wrap-overlay" v-if="showOverlay" @mouseleave="showOverlay=false">
            <el-button type="danger" size="mini" @click="handleOverlayDel">删除</el-button>
            <el-button type="primary" size="mini" @click="handleOverlayEdit">编辑</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-edit" v-if="showGoodsEditPan">
      <div class="edit-title">编辑操作</div>
      <div class="edit-cont">
        <el-form :model="specialForm" status-icon :rules="specialFormRule" label-position="right" ref="specialForm"
                 label-width="120px" class="demo-ruleForm">
          <el-form-item :label="`已选择${specialForm.count}件商品：`" prop="page_data" label-width="130px">
            <el-button type="primary" @click="handlePickerGoods">挑选商品</el-button>
            <div class="tip">提示：可上下拖动调整位置。</div>
          </el-form-item>
        </el-form>
        <div class="picked-goods-wrap-wrap edit-pan">
          <div class="picked-goods-wrap">
            <draggable v-model="goodsData" :options="goodsDataOptions" class="floor-list">
              <div class="picked-goods-item" v-for="(item,i) in goodsData" :key="i">
                <!-- <div class="top-btn-wrap">
                    <div @click="handlePickedGoodsTopBtn(i)" class="top-btn" v-if="i!=0"><i class="el-icon-arrow-up"></i></div>
                </div> -->
                <div class="center">
                  <div class="goods-image"><img :src="item.goods_image"/></div>
                  <div class="goods-cont">
                    <div class="goods-title">{{ item.goods_name }}</div>
                    <div class="goods-price">
                      <span class="price-a">销售价：{{ item.revise_price|formatPrice }}</span>
                      <span class="price-a">市场价：{{ item.mktprice|formatPrice }}</span>
                    </div>
                  </div>
                </div>
                <div class="close-btn-wrap">
                  <div @click="handlePickedGoodsDelBtn(i)" class="close-btn"><i class="el-icon-delete"></i></div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="edit-btn">
        <el-button @click="handleEditPanCancle">取消</el-button>
        <el-button type="primary" @click="handleEditPanSave">保存</el-button>
      </div>
    </div>
    <div class="container-edit" v-else>
      <div class="edit-title">编辑操作</div>
      <div class="edit-cont">
        <el-form :model="specialForm" status-icon :rules="specialFormRule" label-position="right" ref="specialForm"
                 label-width="120px" class="demo-ruleForm">
          <el-form-item label="专题名称：" prop="navigation_name">
            <el-input v-model="specialForm.navigation_name"
                      @change="() => {specialForm.navigation_name = specialForm.navigation_name.trim();}"
                      :maxlength="30" :minlength="3" placeholder="3-60个字符"></el-input>
          </el-form-item>
          <el-form-item label="专题图片：" prop="image">
            <el-upload
              :action="`${MixinUploadApi}?scene=goods`"
              list-type="picture-card"
              :limit="1"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :class="specialForm.image?'noplus':''"
              :file-list="goodsGalleryList">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
          <el-form-item :label="`已选择${specialForm.count}件商品：`" prop="page_data" label-width="130px">
            <el-button type="primary" @click="handlePickerGoods">挑选商品</el-button>
            <div class="tip">提示：可上下拖动调整位置。</div>
          </el-form-item>
        </el-form>
        <div class="picked-goods-wrap-wrap">
          <div class="picked-goods-wrap">
            <draggable v-model="goodsData" :options="goodsDataOptions" class="floor-list">
              <div class="picked-goods-item" v-for="(item,i) in goodsData" :key="i">
                <div class="top-btn-wrap">
                  <div @click="handlePickedGoodsTopBtn(i)" class="top-btn" v-if="i!=0"><i class="el-icon-arrow-up"></i>
                  </div>
                </div>
                <div class="center">
                  <div class="goods-image"><img :src="item.goods_image"/></div>
                  <div class="goods-cont">
                    <div class="goods-title">{{ item.goods_name }}</div>
                    <div class="goods-price">
                      <span class="price-a">销售价：{{ item.revise_price|formatPrice }}</span>
                      <span class="price-a">市场价：{{ item.mktprice|formatPrice }}</span>
                    </div>
                  </div>
                </div>
                <div class="close-btn-wrap">
                  <div @click="handlePickedGoodsDelBtn(i)" class="close-btn"><i class="el-icon-delete"></i></div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="edit-btn">
        <el-button @click="handleCancle">取消</el-button>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </div>
    </div>
    <en-goods-picker-fx
      :show="dialogGoodsShow"
      :showChooseBtn="false"
      :default-data="chooseChangeList"
      @close="dialogGoodsShow = false"
      @choose="choose"
      @chooseAll="allChoose"
      :fdList="fdList"
    />
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import * as API_Floor from '@/api/floor';
import * as API_PageSpecial from '@/api/pageSpecial';

export default {
  name: 'edit',
  components: {draggable},
  data() {
    return {
      goodsData: [],
      showOverlay: false,
      specialForm: {
        navigation_name: '',
        /** 用来校验的专题图片 */
        image: '',
        /** 用来校验的专题商品 */
        page_data: '',
        count: 0,
        shop_id: this.$store.getters.shopInfo.shop_id,
        session: null
      },
      specialFormRule: {
        navigation_name: [
          {
            required: true,
            message: '请输入专题名称',
            trigger: 'blur'
          },
          {
            whitespace: true,
            message: '专题名称不可为纯空格',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 30,
            message: '长度在 3 到 30 个字符',
            trigger: 'blur'
          }
        ],
        image: [
          {
            required: true,
            message: '请选择专题图片',
            trigger: 'change'
          }
        ],
        page_data: [
          {
            required: true,
            message: '请选择专题商品',
            trigger: 'change'
          }
        ],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      goodsDataOptions: {
        animation: 150,
        group: {
          name: 'tplGroup',
          put: true
        },
        sort: true,
        handle: '.picked-goods-item',
      },
      dialogGoodsShow: false,
      chooseChangeList: [],
      // 来源类型
      fdList: [],
      /** 临时存储商品相册信息 */
      goodsGalleryList: [],
      showGoodsEditPan: false

    }
  },
  created() {
    if (this.$route.query.id) {
      this.GET_EditData(this.$route.query.id);
    }
    this.getNavs();
    if (sessionStorage.getItem('session')) {
      const session = sessionStorage.getItem('session');
      this.specialForm.session = session;
    } else {
      this.specialForm.session = null;
    }
  },
  methods: {
    GET_EditData(id) {
      API_PageSpecial.getSpecial(id).then(res => {
        this.specialForm = res;
        this.goodsData = JSON.parse(this.specialForm.page_data);
        this.goodsGalleryList = [{'url': this.specialForm.image}];
      });
    },
    getNavs() {
      API_Floor.getNav().then((res) => {
        this.fdList = res;
      });
    },
    handleOverlayDel() {
      this.$confirm(`确定清空选择的商品吗？`, '提示', {type: 'warning'})
        .then(() => {
          this.goodsData = [];
          this.setSpecialFormCount();
        })
        .catch(() => {
        });
    },
    handleOverlayEdit() {
      this.showGoodsEditPan = true;
    },
    handleCancle() {
      this.$router.go(-1);
    },
    handleSave() {
      let page_data = [];
      this.goodsData.forEach(item => {
        page_data.push({
          goods_id: item.goods_id,
          goods_image: item.goods_image,
          revise_price: item.revise_price,
          mktprice: item.mktprice,
          goods_name: item.goods_name
        })
      })
      if (page_data.length > 0) {
        this.specialForm.page_data = JSON.stringify(page_data);
      } else {
        this.specialForm.page_data = '';
      }
      this.$refs['specialForm'].validate(valid => {
        if (valid) {
          let funcName = this.specialForm.navigation_id ? 'editSpecial' : 'addSpecial'
          API_PageSpecial[funcName](this.specialForm).then(res => {
            this.$message.success(`${this.specialForm.navigation_id ? '编辑' : '添加'}成功`);
            this.$router.push('/special')
          });
        } else {
          this.loading = false;
          this.$message.error('表单中存在未填写或者填写有误的地方,已有错误标示，请检查并正确填写');
        }
      });
    },
    handleEditPanCancle() {
      this.showGoodsEditPan = false;
    },
    handleEditPanSave() {
      // this.showGoodsEditPan = false;
      this.handleSave();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.specialForm.image = '';
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /** 文件列表上传成功时的钩子  上传成功校验 */
    handleSuccess(response, file, fileList) {
      this.specialForm.image = response.url;
      this.$refs['specialForm'].validateField('image');
    },
    handlePickedGoodsTopBtn(index) {
      this.goodsData.unshift.apply(this.goodsData, this.goodsData.splice(index, 1))
    },
    handlePickedGoodsDelBtn(index) {
      this.goodsData.splice(index, 1);
      this.setSpecialFormCount();
    },
    handlePickerGoods() {
      this.dialogGoodsShow = true;
      this.chooseChangeList = this.goodsData;
    },
    allChoose(list) {
      console.log(list);
      for (let i = 0; i < list.length; i++) {
        list[i].goods_image = list[i].thumbnail;
      }
      this.goodsData = list;
      this.setSpecialFormCount();
      this.dialogGoodsShow = false;
    },
    choose(row) {
      row.goods_image = row.thumbnail;
      this.goodsData.unshift(row);
      this.setSpecialFormCount();
      this.dialogGoodsShow = false;
    },
    setSpecialFormCount() {
      this.specialForm.count = this.goodsData.length;
    }

  },
  filters: {
    formatPrice(price) {
      if (!price) return ''
      return '￥' + String(Number(price).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  .container-preview {
    float: left;
    width: 380px;
    height: 900px;
    margin-left: 80px;
    margin-right: 100px;
    margin-top: 20px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 15px 5px rgba(152, 152, 152, 0.35);

    .banner {
      width: 100%;
      height: 200px;
      margin-bottom: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .goods-wrap-wrap {
      overflow: hidden auto;
      height: 650px;

      .goods-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
      }

      .goods-item {
        width: 48.2%;
        background: #fff;
        height: 2.44rem;
        margin-bottom: 0.11rem;
        border-radius: 0.04rem;
        overflow: hidden;
      }

      .name-goods {
        padding: 0 0.1rem;
        padding-top: 0.04rem;
        font-size: 0.12rem;
        color: #333333;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 0.18rem;
        margin: 0.04rem 0;
        height: 0.36rem;
      }

      .price-goods {
        color: #ec3a33;
        font-size: 0.12rem;
        line-height: 0.22rem;
        padding-left: 0.09rem;
      }

      .image-goods img {
        width: 100%;
        height: 1.68rem;
      }

      .goods-wrap-wrap-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding-top: 20px;
        padding-right: 20px;
      }
    }
  }

  .container-edit {
    float: left;
    width: 600px;
    height: 900px;
    box-shadow: 0px 0px 15px 5px rgba(152, 152, 152, 0.35);
    border-radius: 5px 5px 0 0;
    position: relative;
    margin-top: 20px;
    margin-bottom: 50px;

    .edit-title {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      padding-left: 15px;
      align-items: center;
      font-size: 14px;
      background-color: #1a43a9;
      border-radius: 5px 5px 0 0;
      color: #fff;
    }

    .edit-btn {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #fff;
      border-top: 1px solid #dedede;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .edit-cont {
      padding: 15px;

      .picked-goods-wrap-wrap {
        width: 100%;
        height: 420px;
        overflow: hidden auto;

        .picked-goods-wrap {
          width: 100%;
        }

        .picked-goods-item {
          width: 100%;
          height: 140px;
          display: flex;
          justify-content: space-around;
          border: 1px solid #eee;
          background-color: #fff;
          padding: 15px 0;
          margin-bottom: 15px;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            box-shadow: 0px 0px 20px 5px rgba(206, 206, 206, 0.31);
          }

          .top-btn-wrap {
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            cursor: pointer;

            .top-btn {
              width: 40px;
              height: 40px;
              background-color: rgba($color: #1a43a9, $alpha: 0.9);
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .close-btn-wrap {
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            cursor: pointer;

            .close-btn {
              width: 40px;
              height: 40px;
              background-color: rgba($color: #1a43a9, $alpha: 0.9);
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .center {
            width: 70%;
            border: 1px dashed #eee;
            display: flex;
            justify-content: space-between;

            .goods-image {
              width: 30%;
              height: 100%;
              padding: 10px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .goods-cont {
              width: 70%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }
          }
        }
      }

      .picked-goods-wrap-wrap.edit-pan {
        height: 650px;

        .center {
          width: 80%;
        }
      }
    }
  }
}

.noplus /deep/ .el-upload--picture-card {
  display: none;
}
</style>
